import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    currentUser: {},
    company: {},
  }),
  actions: {
    setCurrentBannerId(val) {
      this.currentUser['current_banner_id'] = val
    },
    updateMfaNumber(val) {
      this.currentUser['mfa_number'] = val
    }
  },
  getters: {
    companyName: (state) => {
      return state.company?.name
    },
    completedIntroSurvey: (state) => {
      return state.currentUser.started_required_survey
    },
    currentBannerId: (state) => {
      return state.currentUser.current_banner_id
    },
    email: (state) => {
      return state.currentUser.email
    },
    firstName: (state) => {
      return state.currentUser.name
    },
    firstSurveyRoute: (state) => {
      return state.currentUser.first_survey_route
    },
    googleAuthenticated: (state) => {
      return state.currentUser.google_authenticated
    },
    hasRequests: (state) => {
      return state.currentUser['requested?']
    },
    id: (state) => {
      return state.currentUser.id
    },
    isAdmin: (state) => {
      return !!state.company?.is_admin
    },
    mfaNumber: (state) => {
      return state.currentUser.mfa_number
    },
    mfaAuthenticated: (state) => {
      if(state.company?.mfa_enabled) {
        return state.currentUser.mfa_authenticated
      } else {
        return true
      }
    },
    microsoftAuthenticated: (state) => {
      return state.currentUser.microsoft_authenticated
    }
  }
});